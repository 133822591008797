<template>
  <div ref="dropdownRef" class="dropdown">
    <button class="dropdown-btn" @click="open = !open">
      <slot name="btn" />
    </button>
    <div v-if="open" class="dropdown-content">
      <div v-if="title" class="title">
        {{ props.title }}
      </div>
      <button class="items" @click="open = false">
        <slot />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'
const props = defineProps({
  title: {
    default: '',
    required: false,
    type: String
  }
})
const open = ref(false)
const dropdownRef = ref()
onClickOutside(dropdownRef, () => { open.value = false })
</script>

<style lang="postcss" scoped>
.dropdown{
  @apply relative;
  .dropdown-btn{
    @apply flex items-center font-bold;
  }
  .dropdown-content{
    @apply absolute bg-white rounded border border-gray-300 shadow whitespace-nowrap py-1 mt-1 -right-1;
    .title{
      @apply font-bold px-2 pb-0.5;
    }
    .items{
      @apply block text-left;
      :slotted(*):not(.clear){
        @apply px-2 py-0.5 block cursor-pointer w-full text-left;
        &:hover{
          @apply bg-greenLight;
        }
      }
    }
  }
}
</style>
